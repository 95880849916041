import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as Globals from 'generic/globals'
import React, {Component} from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { PersonInfo } from 'user'
import * as Constants from './constants'
import { PanelBarOption } from '../../components/panel-bar/types'

const headerImgElement = <img src={Globals.CONTENT_POOL_IMG} className="panel-generic-header-icon" />

interface ContentPoolComponentState {
  activeCommunity: any,
  isLoading: boolean,
  person: PersonInfo,
  role: any,
  sections: PanelBarOption[],
}

export class ContentPoolComponent extends Component<GenericNavigatorSelectedItemProps, ContentPoolComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
    this.state = {
      activeCommunity: null,
      isLoading: true,
      person: null,
      role: null,
      sections: [],
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, person, role]) => {
      const sections = Constants.sections(role, activeCommunity.billingInfo.currentPlan)
      this.setState({
        activeCommunity,
        isLoading: false,
        person,
        role,
        sections,
      })
    })
}

  public render() {
    return (
      this.state.isLoading ? <Loading size="64" /> :
      <div>
        <DashboardMenu selected={DashboardMenuOption.content} />
        <div>
          <DesktopNavigator
                selectedItem={this.props.selectedItem}
                sections={this.state.sections}
                baseRoute={DashboardMenuOption.content}
                title="Content"
                subTitle="Manage and view content for your newsletter"
                headerIcon={headerImgElement}
          >
          </DesktopNavigator>
        </div>
      </div>
    );
  }
}
